import React from "react"
import Header from "../../components/header/header"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import ImageGallery from "../../components/gallery/gallery-component"
import { gradphotos } from "../../components/photos/grad-photos"
import "../../../styles/global-styles.scss"

const GraduationParty = () => (
  <Layout>
    <Header />
    <SEO
      title="Graduation Party"
      description="Marking a milestone is always cause for celebration, but graduation deserves a party all its own. Whether it’s a college or high school graduation, YESInspired Events will make your big day even more special. Our creative team of event experts can assist your family with everything from start to finish, including planning, catering and décor."
    />
    <h1 className="gallery-photos-heading">Graduation Party</h1>
    <p className="gallery-photos-text-body">
      Marking a milestone is always cause for celebration, but graduation
      deserves a party all its own. Whether it’s a college or high school
      graduation, YESInspired Events will make your big day even more special.
      Our creative team of event experts can assist your family with everything
      from start to finish, including planning, catering and décor.
    </p>
    <ImageGallery photos={gradphotos} />
  </Layout>
)

export default GraduationParty
