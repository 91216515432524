export const gradphotos = [
  {
    src:
      "https://yesinspiredgallery.s3.us-east-2.amazonaws.com/Fall+Styleshoot+/DSC_0743.jpg",
    width: 3,
    height: 4,
  },
  {
    src:
      "https://yesinspiredgallery.s3.us-east-2.amazonaws.com/Fall+Styleshoot+/DSC_0782.jpg",
    width: 4,
    height: 3,
  },
  {
    src:
      "https://yesinspiredgallery.s3.us-east-2.amazonaws.com/Fall+Styleshoot+/DSC_0791.jpg",
    width: 3,
    height: 4,
  },
  {
    src:
      "https://yesinspiredgallery.s3.us-east-2.amazonaws.com/Fall+Styleshoot+/DSC_0801.jpg",
    width: 3,
    height: 4,
  },
  {
    src:
      "https://yesinspiredgallery.s3.us-east-2.amazonaws.com/Fall+Styleshoot+/DSC_0803.jpg",
    width: 3,
    height: 4,
  },
  {
    src:
      "https://yesinspiredgallery.s3.us-east-2.amazonaws.com/Fall+Styleshoot+/DSC_0473.jpg",
    width: 4,
    height: 3,
  },

  {
    src:
      "https://yesinspiredgallery.s3.us-east-2.amazonaws.com/Fall+Styleshoot+/DSC_0488.jpg",
    width: 3,
    height: 4,
  },
  {
    src:
      "https://yesinspiredgallery.s3.us-east-2.amazonaws.com/Fall+Styleshoot+/DSC_0495.jpg",
    width: 3,
    height: 4,
  },
  {
    src:
      "https://yesinspiredgallery.s3.us-east-2.amazonaws.com/Fall+Styleshoot+/DSC_0570.jpg",
    width: 3,
    height: 4,
  },
  {
    src:
      "https://yesinspiredgallery.s3.us-east-2.amazonaws.com/Fall+Styleshoot+/DSC_0575.jpg",
    width: 3,
    height: 4,
  },
  {
    src:
      "https://yesinspiredgallery.s3.us-east-2.amazonaws.com/Fall+Styleshoot+/DSC_0582.jpg",
    width: 3,
    height: 4,
  },
  {
    src:
      "https://yesinspiredgallery.s3.us-east-2.amazonaws.com/Fall+Styleshoot+/DSC_0603.jpg",
    width: 4,
    height: 3,
  },
  {
    src:
      "https://yesinspiredgallery.s3.us-east-2.amazonaws.com/Fall+Styleshoot+/DSC_0615.jpg",
    width: 4,
    height: 3,
  },

  {
    src:
      "https://yesinspiredgallery.s3.us-east-2.amazonaws.com/Fall+Styleshoot+/DSC_0657.jpg",
    width: 3,
    height: 4,
  },
  {
    src:
      "https://yesinspiredgallery.s3.us-east-2.amazonaws.com/Fall+Styleshoot+/DSC_0705.jpg",
    width: 3,
    height: 4,
  },
  {
    src:
      "https://yesinspiredgallery.s3.us-east-2.amazonaws.com/Fall+Styleshoot+/DSC_0710.jpg",
    width: 3,
    height: 4,
  },
  {
    src:
      "https://yesinspiredgallery.s3.us-east-2.amazonaws.com/Fall+Styleshoot+/DSC_0720.jpg",
    width: 3,
    height: 4,
  },
]
